const frontPageSlider = (container) => {

    /* eslint-disable */
    const Flickity = require('flickity');
    /* eslint-enable */

    const sliderContainer = container.querySelector('.blog__slider');

    let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    console.log(viewportWidth)

    if (sliderContainer) {
        let groupCellsValue;
        let freeScrollValue;
        if (viewportWidth <= 768) {
            groupCellsValue = false;
            freeScrollValue = false;
        } else {
            groupCellsValue = true;
            freeScrollValue = true;
        }
        const slider = new Flickity(sliderContainer, {
            contain: false,
            wrapAround: false,
            prevNextButtons: false,
            pageDots: false,
            freeScroll: freeScrollValue,
            groupCells: groupCellsValue,
        });

        slider.on('dragStart', function () {
            let dragSlider = document.querySelector('.flickity-slider')
            dragSlider.style.pointerEvents = 'none';
        });

        slider.on('dragEnd', function () {
            let dragSlider = document.querySelector('.flickity-slider')
            dragSlider.style.pointerEvents = 'all';
        });

        let sliderTitle = document.querySelector('.blog__slider__title')
        let sliderBlock = document.querySelector('.blog__slider__outer')
        let sliderTitleOffsetLeft =  sliderTitle.offsetLeft - 20;
        sliderBlock.style.left = sliderTitleOffsetLeft+'px';
    }

}

export default frontPageSlider;
