import gsap from 'gsap';
import imageMapResize from 'image-map-resizer';

const pageEnter = (container) => {

    imageMapResize();

    // Set barba prevent to self on fancybox
    let listFancybox = container.querySelectorAll('[data-fancybox]');
    let arrayFancybox = Array.prototype.slice.call(listFancybox);
    for (let i = 0; i < arrayFancybox.length; i++) {
        arrayFancybox[i].setAttribute('data-barba-prevent', 'self');
    }

    setTimeout(() => {
        if (window.location.href.indexOf('gf_1') > -1) {
            document.getElementById('gf_1').scrollIntoView();
        } else if (window.location.href.indexOf('gf_2') > -1) {
            document.getElementById('gf_2').scrollIntoView();
        } else if (window.location.href.indexOf('gf_3') > -1) {
            document.getElementById('gf_3').scrollIntoView();
        } else if (window.location.href.indexOf('gf_4') > -1) {
            document.getElementById('gf_4').scrollIntoView();
        } else {
            window.scrollTo(0,0)
        }
    }, '300')

    // data-barba-prevent="self"
    // reservierung

    $('a').each(function () {
        if ($(this).attr('href').indexOf('reservierung') > -1 || $(this).attr('href').indexOf('reservering') > -1) {
            $(this).attr('data-barba-prevent', 'self');
        }
    });



    /**
     * Add scroll class to all area
     */
    let allArea = container.getElementsByTagName('area');
for (let i = 0; i < allArea.length; i++) {
    allArea[i].classList.add('scroll__to');
}

    /**
     * Scroll to smooth
     */
    let smooth = container.querySelectorAll('.scroll__to')

for (let i = 0; i < smooth.length; i++) {
    smooth[i].addEventListener('click',  function (e) {
        e.preventDefault()
        let hash = this.hash.replace(/^.*#/, '');
        let offset = -100;
        let element = document.getElementById(hash);
        let scroll = element.getBoundingClientRect().top + window.pageYOffset + offset;

        window.scrollTo({top: scroll, behavior: 'smooth'});
    });
}

    /**
     * Menu background images
     */
    let menuImage = container.querySelectorAll('.menu__image');
    let menuImageArray = Array.prototype.slice.call(menuImage);

    let menuActive = container.querySelector('.current-menu-item');
if (menuActive) {
    menuActive = menuActive.querySelector('.menu__data__image');
    if (menuActive !== null) {
        let menuActiveImage = menuActive.getAttribute('data-image');

        for (let i = 0; i < menuImageArray.length; i++) {
            menuImageArray[i].style.backgroundImage = 'url('+menuActiveImage+')';
        }
    }
}

    let menuBlock = container.querySelector('.menu__block');
    let menuItems = menuBlock.querySelectorAll('.menu-item:not(.current-menu-item)');
    let menuItemsArray = Array.prototype.slice.call(menuItems);


for (let i = 0; i < menuItemsArray.length; i++) {
    menuItemsArray[i].addEventListener('mouseover', function () {

        let hoverImage = menuItemsArray[i].querySelector('.menu__data__image').getAttribute('data-image');
        if ( hoverImage ) {
            // fade out default images
            for (let i = 0; i < menuImageArray.length; i++) {
                menuImageArray[i].style.opacity = 0;
            }

            // set new images
            let newImage = menuItemsArray[i].querySelector('.menu__data__image').getAttribute('data-image');

            for (let i = 0; i < menuImageArray.length; i++) {
                menuImageArray[i].style.backgroundImage = 'url('+newImage+')';
                menuImageArray[i].style.opacity = 0.1;
            }
        }
    });
}


    const tl = gsap.timeline();

    let menuLayer1 = container.querySelector('.menu__layer1');
    let menuLayer2 = container.querySelector('.menu__layer2');

    tl
        .set(menuLayer1, {clearProps: 'all'})
        .fromTo(menuLayer2, {autoAlpha: '1', height: '100%'}, { autoAlpha: '0', duration: 0.3, ease: 'none'}, 0)
        .set(menuLayer2, {clearProps: 'all'})

    return tl;

}

export default pageEnter;
