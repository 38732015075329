import barba from '@barba/core';

// Page
import pageLeave from './animations/pageLeave'
import pageEnter from './animations/pageEnter'
import homeEnter from './animations/homeEnter'
import frontPageSlider from './animations/frontPageSlider'
import menu from './animations/menu'
import blogEnter from './animations/blogEnter';
import gravityforms from './animations/gravitforms';
import contactEnter from './animations/contactEnter';
import cowPercent from './animations/cowPercent';
import defaultEnter from './animations/defaultEnter'
import blogDetailEnter from './animations/blogDetailEnter';
import bodyClass from './animations/bodyClass';
import MeatOrder from './modules/meatOrder';

export default {
    init() {
        barba.init({
            debug: false,

            transitions: [
                {
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container);
                        frontPageSlider(next.container);
                        MeatOrder(next.container);
                        gravityforms(next.container);
                    },
                    after(data) {
                        menu(data);
                        bodyClass();
                    },
                    once(data) {
                        pageEnter(data.next.container);
                        frontPageSlider(data.next.container);
                        menu(data);
                        MeatOrder(data.next.container);
                    },
                },
                {
                    name: 'home',
                    to: {
                        namespace: ['home'],
                    },
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container);
                        homeEnter(next.container);
                        frontPageSlider(next.container);
                    },
                    after(data) {
                        menu(data);
                        bodyClass();

                    },
                    once(data) {
                        pageEnter(data.next.container);
                        frontPageSlider(data.next.container);
                        menu(data);
                    },
                },
                {
                    name: 'default',
                    to: {
                        namespace: ['default'],
                    },
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container);
                        defaultEnter(next.container);
                        frontPageSlider(next.container);
                    },
                    after(data) {
                        menu(data);
                        bodyClass();
                        cowPercent(data);
                    },
                    once(data) {
                        pageEnter(data.next.container);
                        frontPageSlider(data.next.container);
                        menu(data);
                        cowPercent(data);
                    },
                },
                {
                    name: 'blog',
                    to: {
                        namespace: ['blog'],
                    },
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container);
                        blogEnter(next.container);
                        frontPageSlider(next.container);
                    },
                    after(data) {
                        menu(data);
                        bodyClass();
                    },
                    once(data) {
                        pageEnter(data.next.container);
                        frontPageSlider(data.next.container);
                        menu(data);
                    },
                },
                {
                    name: 'blog-detail',
                    to: {
                        namespace: ['blog-detail'],
                    },
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container);
                        blogDetailEnter(next.container);
                        frontPageSlider(next.container);
                    },
                    after(data) {
                        menu(data);
                        bodyClass();
                    },
                    once(data) {
                        frontPageSlider(data.next.container);
                        menu(data);
                    },
                },
                {
                    name: 'contact',
                    to: {
                        namespace: ['contact'],
                    },
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container);
                        contactEnter(next.container);
                        frontPageSlider(next.container);
                        gravityforms(next.container);
                    },
                    after(data) {
                        menu(data);
                        bodyClass();
                    },
                    once(data) {
                        frontPageSlider(data.next.container);
                        menu(data);
                    },
                },
            ],
        });
    },
}
