export default {
    init: function () {
        // JavaScript to be fired on all page

        $('.fancybox__thumb').fancybox({
            thumbs : {
                autoStart : true,
                axis      : 'x',
            },
        })

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
